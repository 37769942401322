<template>
  <div class="page-box" ref="page">
    <a-spin tip="Loading..." :spinning="formLoading">
      <a-form-model :loading="formLoading" ref="ruleForm" :model="form" :rules="disabled?{}:rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="活动名称：" prop="activityName">
          <a-input v-model="form.activityName" v-if="!disabled" style="width: 300px" allowClear placeholder="活动名称" />
          <div v-else>{{form.activityName}}</div>
        </a-form-model-item>
        <a-form-model-item label="活动时间：" prop="activityDate">
          <div v-if="!disabled">
            <a-date-picker v-model="form.beginTime" :disabled-date="disabledStartDate" show-time format="YYYY-MM-DD HH:mm:ss" placeholder="开始时间" @change="changeDate" :disabled="form.activityState===1" />
            <span style='margin:0 8px'>至</span>
            <a-date-picker @change="changeDate" v-model="form.endTime" :disabled-date="disabledEndDate" show-time format="YYYY-MM-DD HH:mm:ss" placeholder="结束时间" :disabled-time="disabledEndDateTime" :disabled="!form.beginTime" />
          </div>
          <div v-else>{{moment(form.beginTime||new Date()).format('YYYY-MM-DD HH:mm:ss')}} 至 {{moment(form.endTime||new Date()).format('YYYY-MM-DD HH:mm:ss')}}</div>
        </a-form-model-item>
        <a-form-model-item label="拼团商品：" prop="groupProductList">
          <a-button v-if="form.activityState!==1" type="link" @click="selectProduct" :disabled="!!activityId">选择商品</a-button>
          <a-table bordered :pagination="false" v-if="form.groupProductList.length" :columns="disabled?[columns1[0]]:columns1" :rowKey="record => record.productId" :dataSource="form.groupProductList">
            <template slot="name" slot-scope="text,record">
              <div class="good-name-box">
                <base-img :src="record.productImg" style="flex-shrink:0;margin-right:8px;" width="60" height="60"></base-img>
                <div class="good-name">
                  <div class="name">{{record.name}}</div>
                  <div>¥{{record.price}}</div>
                </div>
              </div>
            </template>
            <template slot="operation">
              <a-button type="link" style="padding:0" :disabled="!!activityId" @click="deletSelectProduct">删除</a-button>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item label="拼团价设置：" prop="groupPrice" :required="!disabled" v-if="form.groupProductList.length&&form.groupProductList[0].skuList.length">
          <a-table bordered :pagination="false" :columns="form.groupProductList[0].skuList[0].skuName ?columns2:columns3" :rowKey="(record,index) => index" :dataSource="form.groupProductList[0].skuList">
            <template slot-scope="text,record" slot="skuName">
              {{record.skuName||form.groupProductList[0].name}}
            </template>
            <template slot-scope="text,record" slot="skuPic">
              <base-img class="goods-img" :src="record.skuPic||form.groupProductList[0].productImg" width="60" height="60" oss-style="60_60" />
            </template>
            <template slot-scope="text,record" slot="groupPrice">
              <a-form-model class="group-price-form" :model="record" :rules="priceRules">
                <a-form-model-item label prop="groupPrice">
                  <div v-if="!disabled">
                    <a-input :disabled="form.activityState===1" addon-before="¥" @change="changeGroupPrice($event,record)" v-model="record.groupPrice" placeholder="请输入拼团价" style="width:200px"></a-input>
                  </div>
                  <div v-else>{{record.groupPrice}}</div>
                </a-form-model-item>
              </a-form-model>
            </template>
          </a-table>
          <div style="font-size:12px;color: red;line-height:20px;margin-top:6px;">
            <p>拼团价不得低于成本价，不得高于零售价。</p>
          </div>
          <div class="batch-box" v-if="form.activityState!==1">
            <p>批量设置：</p>
            <a-button type="link" @click="batchShow = true">拼团价</a-button>
            <a-form-model slot="content" v-if="batchShow" layout="inline" class="group-price-form" ref="formBatch" :model="batch" :rules="priceRules">
              <a-form-model-item label prop="groupPrice">
                <a-input :disabled="form.activityState===1" addon-before="¥" v-model="batch.groupPrice" placeholder="请输入拼团价" style="width:200px" allowClear></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" size="small" style="margin-right:12px" @click="changeBatchGroupPrice">确定</a-button>
                <a-button type="default" size="small" @click="batch={};batchShow = false">取消</a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-form-model-item>
        <a-form-model-item label="拼团有效期：" :required="!disabled" prop="validityTime">
          <div v-if="!disabled">
            <a-select style="width: 80px" @change="changeValidtyTime" v-model="form.validDay" :disabled="form.activityState===1">
              <a-select-option default-value="lucy" v-for="d in 31" :key="d-1" :value="d-1">{{d-1}}</a-select-option>
            </a-select>
            <span style="padding: 0 32px 0 8px;">天</span>
            <a-select style="width: 80px" @change="changeValidtyTime" v-model="form.validHour" :disabled="form.activityState===1">
              <a-select-option v-for="h in 60" :key="h-1" :value="h-1">{{h-1}}</a-select-option>
            </a-select>
            <span style="padding: 0 32px 0 8px;">小时</span>
            <a-select style="width: 80px" @change="changeValidtyTime" v-model="form.validMinute" :disabled="form.activityState===1">
              <a-select-option v-for="m in 60" :key="m-1" :value="m-1">{{m-1}}</a-select-option>
            </a-select>
            <span style="padding: 0 32px 0 8px;">分钟</span>
            <div style="font-size:12px;color: #999;line-height:20px;">
              <p>拼团有效期需在15分钟至30天之间</p>
              <p>若设置1日，用户开团后，需要在1日内成团，超时则拼团失败</p>
            </div>
          </div>
          <div v-else>{{form.validDay}}天{{form.validHour}}时{{form.validMinute}}分</div>
        </a-form-model-item>
        <a-form-model-item label="参团人数：" prop="groupNum">
          <a-input v-if="!disabled" v-model="form.groupNum" addon-after="人" style="width: 200px" allowClear placeholder="2-100人" :disabled="form.activityState===1" />
          <div v-else>{{form.groupNum}}人</div>
        </a-form-model-item>
        <a-form-model-item label="凑团：" prop="makeGroupFlag">
          <a-checkbox v-if='!disabled' name="makeGroupFlag" v-model="form.makeGroupFlag">开启</a-checkbox>
          <div v-else>{{form.makeGroupFlag?'开启':'未开启'}}</div>
          <p style="font-size:12px;color: #999;line-height:20px;">开启凑团后，活动商品详情页展示未成团的团列表，买家可以任选一个团参团，提升成团率。</p>
        </a-form-model-item>
        <a-form-model-item label="模拟成团：" prop="simulationFlag">
          <a-checkbox v-if="!disabled" name="simulationFlag" v-model="form.simulationFlag">开启</a-checkbox>
          <div v-else>{{form.simulationFlag?'开启':'未开启'}}</div>
          <p style="font-size:12px;color: #999;line-height:20px;">开启模拟成团后，满足条件的团，系统将会模拟“匿名买家”凑满该团，仅需对真实拼团买家发货。建议合理开启，以提高成团率。</p>
          <div v-if="form.simulationFlag&&!disabled">
            <a-radio-group name="simulationState" v-model="form.simulationState">
              <a-radio :value="1">所有未拼成的团</a-radio>
              <a-radio :value="2">
                参团人数&ge;
                <a-input v-model="form.simulationMinNum" :disabled="form.simulationState!==2" style="width: 100px" allowClear></a-input>人的团
              </a-radio>
            </a-radio-group>
          </div>
          <div v-if='form.simulationState === 1 && disabled'>{{form.simulationState === 1?'所有未拼成的团':'参团人数&ge;'+form.simulationMinNum+人的团}}</div>
        </a-form-model-item>
        <a-row type="flex" align="middle" justify="center">
          <a-button type="primary" @click="onSubmit" :loading="saveBtnLoading">保存</a-button>
          <a-button style="margin-left: 10px;" @click="closePage">取消</a-button>
        </a-row>
      </a-form-model>
    </a-spin>
    <a-modal title="选择商品" :visible="modalShow" width="800px" @cancel="modalCancel" @ok="modalConfirm" :getContainer="()=>$refs.page">
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="width: 200px;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="selectProduct">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table bordered :columns="columns" :dataSource="productList" :pagination="pagination" :rowKey="record => record.productId" :loading="modalLoading" :row-selection="{ type:'radio',selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps  }" @change="handleTableChange">
        <template slot="name" slot-scope="text,record">
          <div class="good-name-box">
            <base-img :src="record.productImg" style="flex-shrink:0;margin-right:8px;" width="60" height="60"></base-img>
            <div class="good-name">
              <a-button class="name" type="link" @click="goGoodDetail(record.productId)">{{record.name}}</a-button>
              <div style="color:#ED6A0C;">¥{{record.price}}</div>
            </div>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { activityTypes } from '@/utils/constants'
import { checkFloat } from '@/utils/validator'
export default {
  name: 'PageGroupActivityAdd',
  setup (props, { root }) {
    // modal
    const columns = [
      {
        title: '商品名称',
        dataIndex: 'name',
        width: 300,
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '库存',
        dataIndex: 'stock',
      },
      {
        title: '销量',
        dataIndex: 'sales',
      },
      {
        title: '已参与活动名称',
        dataIndex: 'activityName',
        customRender: (x) => {
          return x || '-'
        },
      },
      {
        title: '不可选原因',
        dataIndex: 'activityType',
        customRender: (x, record) => {
          if (x) {
            return '该商品正在参加' + activityTypes.find((item) => item.value === x).name + '活动'
          } else {
            return '-'
          }
        },
      },
    ]
    // 商品
    const columns1 = [
      {
        title: '商品名称',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ]
    // 商品规格
    const columns2 = [
      {
        title: '商品图',
        dataIndex: 'skuPic',
        scopedSlots: { customRender: 'skuPic' },
      },
      {
        title: '规格名',
        dataIndex: 'skuName',
        scopedSlots: { customRender: 'skuName' },
      },
      {
        title: '零售价（元）',
        dataIndex: 'price',
      },
      {
        title: '成本价（元）',
        dataIndex: 'supplyPrice',
      },
      {
        title: '拼团价（元）',
        dataIndex: 'groupPrice',
        scopedSlots: { customRender: 'groupPrice' },
      },
      {
        title: '库存',
        dataIndex: 'stock',
      },
    ]
    const columns3 = [
      {
        title: '商品图',
        dataIndex: 'skuPic',
        scopedSlots: { customRender: 'skuPic' },
      },
      {
        title: '零售价（元）',
        dataIndex: 'price',
      },
      {
        title: '成本价（元）',
        dataIndex: 'supplyPrice',
      },
      {
        title: '拼团价（元）',
        dataIndex: 'groupPrice',
        scopedSlots: { customRender: 'groupPrice' },
      },
      {
        title: '库存',
        dataIndex: 'stock',
      },
    ]
    const priceRules = {
      groupPrice: [
        { validator: checkFloat, trigger: 'change' },
        { required: true, message: '请输入拼团价', trigger: 'change' },
      ],
    }
    const validityTime = (rule, value, callback) => {
      if (
        (state.form.validDay === 0 && state.form.validHour === 0 && state.form.validMinute < 15) ||
        (state.form.validDay === 30 && state.form.validHour > 0) ||
        (state.form.validDay === 30 && state.form.validMinute > 0)
      ) {
        callback(new Error('拼团有效期需在15分钟至30天之间'))
      } else {
        callback()
      }
    }
    const validityGroupPrice = (rule, value, callback) => {
      let hasNoGroupPrice = false
      state.form.groupProductList[0].skuList.forEach((item) => {
        if (item.groupPrice === '' || item.groupPrice === undefined) {
          hasNoGroupPrice = true
        }
      })
      if (hasNoGroupPrice) {
        callback(new Error('请设置拼团价'))
      } else {
        callback()
      }
    }
    const validityActivityDate = (rule, value, callback) => {
      if (!state.form.beginTime || !state.form.endTime) {
        callback(new Error('请选择活动时间'))
      } else {
        callback()
      }
    }
    const labelCol = { span: 2 }
    const wrapperCol = { span: 18 }
    const rules = {
      activityName: [
        { required: true, message: '请输入活动名称', trigger: 'change' },
        { max: 32, message: '请尝试简短一些的活动名称', trigger: 'change' },
      ],
      activityDate: [
        { required: true, message: '请选择活动时间', trigger: 'change' },
        { validator: validityActivityDate },
      ],
      groupPrice: [{ validator: validityGroupPrice }],
      groupProductList: [{ required: true, message: '请选择活动商品', trigger: 'change' }],
      validityTime: [{ validator: validityTime }],
      groupNum: [
        { required: true, message: '请输入参团人数', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (value) {
              const reg = /^[0-9]*[1-9][0-9]*$/
              if (reg.test(value)) {
                if (Number(value) < 2 || Number(value) > 100) {
                  return callback(new Error('请输入2-100'))
                } else {
                  callback()
                }
              } else {
                return callback(new Error('请输入一个整数'))
              }
            } else {
              callback()
            }
          },
        },
      ],
    }
    onMounted(() => {
      if (root.$route.path.indexOf('/activity/group/detail') !== -1) {
        state.disabled = true
      } else {
        state.disabled = false
      }
      state.activityId = root.$route.params.id || ''
      if (state.activityId) {
        getData()
      }
    })
    const state = reactive({
      activityId: '',
      formLoading: false,
      modalShow: false,
      modalLoading: false,
      batchShow: false,
      saveBtnLoading: false,
      form: {
        validDay: 0,
        validHour: 0,
        validMinute: 0,
        makeGroupFlag: true,
        simulationState: 1,
        groupProductList: [],
        validityTime: '1',
        groupPrice: '1',
        activityDate: '1',
        beginTime: null,
        endTime: null,
      },
      batch: {},
      productList: [],
      formBatch: null,
      ruleForm: null,
      productName: '',
      selectProductData: {},
      selectedRowKeys: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small',
      },
      disabled: false,
    })
    function onSubmit () {
      state.ruleForm.validate(async (valid) => {
        if (valid) {
          if (
            state.form.simulationFlag &&
            state.form.simulationState === 2 &&
            !state.form.simulationMinNum
          ) {
            return root.$message.error('请设置模拟成团最低参团人数')
          }
          if (
            state.form.simulationFlag &&
            state.form.simulationState === 2 &&
            Number(state.form.simulationMinNum) >= Number(state.form.groupNum)
          ) {
            return root.$message.error('模拟成团最低参团人数需小于参团总人数')
          }
          const skuPriceValid = skuPriceValidate()
          if (skuPriceValid) {
            return root.$message.error(skuPriceValid)
          }
          let param = {
            groupActivity: {
              shopId: getSession(SHOPID),
              activityName: state.form.activityName,
              beginTime: moment(state.form.beginTime).format('YYYY-MM-DD HH:mm:ss'),
              endTime: moment(state.form.endTime).format('YYYY-MM-DD HH:mm:ss'),
              validHour: state.form.validHour,
              validDay: state.form.validDay,
              validMinute: state.form.validMinute,
              groupNum: state.form.groupNum,
              makeGroupFlag: state.form.makeGroupFlag,
              simulationState: state.form.simulationFlag ? state.form.simulationState : 0,
              simulationMinNum:
                state.form.simulationFlag && state.form.simulationState === 2
                  ? state.form.simulationMinNum
                  : '',
            },
            groupProductList: state.form.groupProductList.map((item) => {
              item.skuList.forEach((val) => {
                val.productMainPic = item.productImg
                val.productName = item.name
                val.subTitle = item.subTitle
              })
              return item.skuList
            })[0],
          }
          if (state.activityId) {
            param.groupActivity.groupActivityId = state.form.groupActivityId
            param.groupActivity.activityState = state.form.activityState
          }

          if (state.activityId) {
            state.saveBtnLoading = true
            let { code, msg } = await activity.editGroupActivity(param)
            state.saveBtnLoading = false
            if (code === '00000') {
              root.$bus.$emit('PageGroupList:refresh')
              root.$message.success('修改成功')
              closePage()
            } else {
              root.$message.error(msg || '修改失败')
            }
          } else {
            state.saveBtnLoading = true
            let { code, msg } = await activity.saveGroupActivity(param)
            state.saveBtnLoading = false
            if (code === '00000') {
              root.$bus.$emit('PageGroupList:refresh')
              root.$message.success('保存成功')
              closePage()
            } else {
              root.$message.error(msg || '保存失败')
            }
          }
        }
      })
    }
    function closePage () {
      if (root.$store.state.tabs.views.map((item) => item.path).indexOf('/activity/group') !== -1) {
        root.$closeCurrentView()
      } else {
        root.$closeCurrentView('/activity/group')
      }
    }
    async function selectProduct () {
      state.modalShow = true
      state.modalLoading = true
      let { code, data, page, msg } = await activity.getActivitySelectProductpage({
        current: state.productName !== '' ? 1 : state.pagination.current,
        size: state.pagination.pageSize,
        name: state.productName,
      })
      state.modalLoading = false
      if (code === '00000') {
        state.productList = data.map((item) => {
          if (item.skuList.length === 0) {
            item.skuList.push({
              price: item.price,
              stock: item.stock,
              productId: item.productId,
            })
          }
          return item
        })
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      selectProduct()
    }
    function onSelectChange (selectedRowKeys, selectedRows) {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    function getCheckboxProps (record) {
      return {
        props: { disabled: !!record.activityType },
      }
    }
    function modalCancel () {
      state.selectedRowKeys = []
      state.selectedRows = []
      state.modalShow = false
    }
    function modalConfirm () {
      state.selectedRows[0].skuList.forEach((item) => {
        item.groupPrice = ''
      })
      root.$set(state.form, 'groupProductList', deepClone(state.selectedRows))
      state.ruleForm.validateField('groupProductList')
      state.modalShow = false
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function goGoodDetail (productId) {
      root.$router.push(`/goods/goods_detail/${productId}`)
    }
    function deletSelectProduct () {
      state.selectedRowKeys = []
      state.selectedRows = []
      state.form.groupProductList = []
    }
    function changeBatchGroupPrice (e) {
      state.formBatch.validate(async (valid) => {
        if (valid) {
          root.$set(
            state.form.groupProductList[0],
            'skuList',
            state.form.groupProductList[0].skuList.map((item, index) => {
              item.groupPrice = Number(state.batch.groupPrice)
              return item
            })
          )
          state.batchShow = false
          state.ruleForm.validateField('groupPrice')
        }
      })
    }
    function resetSearch () {
      state.productName = ''
      selectProduct()
    }
    async function getData () {
      let { code, data, msg } = await activity.getGroupActivityDetail(state.activityId)
      if (code === '00000') {
        if (data.groupActivity.simulationState !== 0) {
          data.groupActivity.simulationFlag = true
        }
        data.selectVos.forEach((item) => {
          item.skuList.forEach((val) => {
            if (!val.skuName) {
              val.skuName = item.name
            }
          })
        })
        state.form = {
          validityTime: '1',
          groupPrice: '1',
          activityDate: '1',
          ...data.groupActivity,
          beginTime: moment(data.groupActivity.beginTime),
          endTime: moment(data.groupActivity.endTime),
          groupProductList: data.selectVos.map((item) => {
            if (item.skuList.length === 0) {
              item.skuList.push({
                price: item.price,
                stock: item.stock,
                productId: item.productId,
                groupPrice: item.groupPrice,
                skuName: item.skuName,
              })
            }
            return item
          }),
        }
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function changeValidtyTime () {
      state.form.validityTime = 1
      state.ruleForm.validateField('validityTime')
    }
    function disabledStartDate (startValue) {
      const endValue = moment(state.form.endTime)
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    }
    function disabledEndDate (endValue) {
      const startValue = moment(state.form.beginTime)
      const nowValue = moment(new Date())
      if (!endValue || !startValue) {
        return false
      }
      if (nowValue.valueOf() >= startValue.valueOf()) {
        return nowValue.valueOf() - 24 * 3600 * 1000 >= endValue.valueOf()
      } else {
        return startValue.valueOf() >= endValue.valueOf()
      }
    }
    function range (start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    function disabledEndDateTime () {
      // 结束时间 比开始时间大于 1天，时分秒没有限制
      if (
        state.form.endTime &&
        new Date(state.form.endTime).getTime() - new Date(state.form.beginTime).getTime() >
          24 * 3600 * 1000
      ) {
        return {}
      } else {
        const startValue = moment(state.form.beginTime)
        const nowValue = moment(new Date())
        let dateTime = ''
        if (nowValue.valueOf() >= startValue.valueOf()) {
          dateTime = new Date()
        } else {
          dateTime = state.form.beginTime
        }
        return {
          disabledHours: () => range(0, new Date(dateTime).getHours()),
          disabledMinutes: () => {
            if (
              state.form.endTime &&
              new Date(state.form.endTime).getHours() > new Date(dateTime).getHours()
            ) {
              return []
            } else {
              return range(0, new Date(dateTime).getMinutes())
            }
          },
          disabledSeconds: () => {
            if (
              state.form.endTime &&
              new Date(state.form.endTime).getHours() > new Date(dateTime).getHours()
            ) {
              return []
            } else if (
              state.form.endTime &&
              new Date(state.form.endTime).getHours() === new Date(dateTime).getHours() &&
              new Date(state.form.endTime).getMinutes() > new Date(dateTime).getMinutes()
            ) {
              return []
            } else {
              return range(0, new Date(dateTime).getSeconds())
            }
          },
        }
      }
    }
    function changeDate () {
      state.ruleForm.validateField('activityDate')
    }
    function changeGroupPrice (e, record) {
      state.batch = {}
      state.ruleForm.validateField('groupProductList')
    }
    // sku提交价格验证
    function skuPriceValidate () {
      for (let i = 0; i < state.form.groupProductList[0].skuList.length; i++) {
        const record = state.form.groupProductList[0].skuList[i]
        const warningTitle = `拼团价设置规格第${i + 1}条，`
        if (
          /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/.test(record.groupPrice) &&
          Number(record.groupPrice) > Number(record.price)
        ) {
          return `${warningTitle}拼团价不得高于零售价`
        }
        if (
          /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/.test(record.groupPrice) &&
          Number(record.groupPrice) < Number(record.supplyPrice)
        ) {
          return `${warningTitle}拼团价不得低于成本价`
        }
      }
      return ''
    }
    return {
      disabledStartDate,
      disabledEndDate,
      disabledEndDateTime,
      labelCol,
      wrapperCol,
      rules,
      moment,
      priceRules,
      columns,
      columns1,
      columns2,
      columns3,
      ...toRefs(state),
      getData,
      onSubmit,
      selectProduct,
      handleTableChange,
      onSelectChange,
      getCheckboxProps,
      modalCancel,
      modalConfirm,
      goGoodDetail,
      deletSelectProduct,
      changeBatchGroupPrice,
      resetSearch,
      changeValidtyTime,
      closePage,
      changeDate,
      changeGroupPrice,
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .good-name-box {
  display: flex;
  align-items: center;
  width: 300px;
  .good-name {
    min-height: 60px;
    width: 232px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      padding: 0;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
}
.group-price-form {
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
}
.batch-box {
  display: flex;
  align-items: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-end;
}
</style>
